import { ButtonsForm } from "@components/ButtonsForm"
import { Icon } from "@components/Icons"
import styles from "@modules/Banners/style.module.less"
import {
  AutoComplete,
  Card,
  Col,
  DatePicker,
  Form,
  Modal,
  Radio,
  Row,
  Select,
  Upload,
} from "antd"
import cx from "classnames"
import { navigate } from "gatsby"
import _get from "lodash/get"
import _map from "lodash/map"
import React from "react"

const FormGeneralEssay = (props) => {
  const {
    isEdit,
    loading,
    loadingAffiliated,
    models,
    categories,
    bannerImage,
    handleChangeRadio,
    typeSelected,
    previewBanner,
    cancelPreviewBanner,
    handlePreviewBanner,
    handleRemoveBanner,
    handleChangeBanner,
    handleRemoveEssayBanner,
  } = props

  return (
    <>
      <div className="generalContext">
        <Col span={24} className="colunmMain">
          <div
            className={
              !isEdit
                ? cx("bg-white br2 ph16 ph40-l", styles.cardUpload)
                : cx("bg-white br2 ph16 ph40-l", styles.cardUploadEdit)
            }
          >
            <Col span={24} className="contentType">
              <Form.Item name="type">
                <Radio.Group
                  onChange={handleChangeRadio}
                  value={typeSelected}
                  className="radio-banner"
                  name="radiogroup"
                >
                  <Radio value={true}>Padrão</Radio>
                  <Radio value={false}>Apenas imagem</Radio>
                </Radio.Group>
              </Form.Item>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Nome da modelo"
                  name="modelName"
                  rules={[
                    {
                      required: typeSelected,
                      message:
                        "Por favor, selecione uma modelo para o cadastro.",
                    },
                  ]}
                >
                  <AutoComplete
                    disabled={loadingAffiliated || !typeSelected}
                    className={styles.borderInput}
                    allowClear
                    options={_map(models, (model) => ({
                      label: model.name,
                      value: model.name,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Data de lançamento do ensaio"
                  name="releaseDate"
                  rules={[
                    {
                      required: typeSelected,
                      message: "Por favor, selecione a data do ensaio.",
                    },
                  ]}
                >
                  <DatePicker
                    disabled={!typeSelected}
                    className={styles.pickerWidth}
                    placeholder="ex.: 20/02/2021"
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
              </Col>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Categoria"
                name="category"
                rules={[
                  {
                    required: typeSelected,
                    message: "Por favor, selecione a categoria deste banner.",
                  },
                ]}
              >
                <Select disabled={!typeSelected} allowClear>
                  {_map(categories, (category) => (
                    <Select.Option key={category.id} value={category.id}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <div>
              <Card className="bannerContext">
                <p className="mb4 segoeui f16 fw6 lh-copy">
                  {isEdit ? "Atualize o banner." : "Adicione um novo banner."} A
                  medida deve ser de 1550x1035
                </p>

                <Row>
                  <Col span={24}>
                    <div className="draggerCover">
                      <Form.Item
                        name="photoImage"
                        rules={[
                          {
                            required: true,
                            message: "Por favor, adicione um banner.",
                          },
                        ]}
                      >
                        <Upload.Dragger
                          multiple={false}
                          onPreview={handlePreviewBanner}
                          onChange={handleChangeBanner}
                          listType="picture-card"
                          accept=".jpeg, .png, .jpg"
                          maxCount={1}
                          fileList={_get(bannerImage, "fileList", null)}
                          onRemove={handleRemoveBanner}
                          customRequest={({ onSuccess }) => {
                            setTimeout(() => {
                              onSuccess("ok")
                            }, 0)
                          }}
                        >
                          <p>
                            <Icon name="Inbox" className="f48 mb16" />
                          </p>

                          <p>Clique ou arraste a foto para esta área.</p>
                        </Upload.Dragger>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
          </div>
        </Col>
      </div>

      <ButtonsForm
        onCancel={() => navigate("/admin/banners")}
        saveText={isEdit ? "Salvar alterações" : "Cadastrar banner"}
        cancelText="Descartar"
        buttonLoading={loading}
        showRemoveButton={isEdit}
        removeButtonText="Remover banner"
        contentWidth="calc(100vw - 256px)"
        handleRemoveEssay={handleRemoveEssayBanner}
        removeDialogText="Deseja realmente remover este banner?"
      />

      <Modal
        visible={previewBanner.visible}
        title={previewBanner.title}
        onCancel={() => cancelPreviewBanner(false)}
        footer={null}
      >
        <img
          alt="Pré-visualização"
          className="w-100"
          src={previewBanner.image}
        />
      </Modal>
    </>
  )
}

export default FormGeneralEssay
