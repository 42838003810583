import "./ButtonsForm.less"

import { DialogModal, DialogTypes } from "@components/DialogModal"
import { Button, Col, Form, Row } from "antd"
import cx from "classnames"
import React, { useState } from "react"

export const ButtonsForm = ({
  onCancel,
  buttonLoading = false,
  cancelText = "Cancelar",
  saveText = "Salvar",
  showConfirmModal = true,
  onClickSave,
  fixed = true,
  showSaveButton = true,
  withoutBackground = false,
  showRemoveButton = false,
  removeButtonText,
  removeDialogText,
  contentWidth,
  handleRemoveEssay,
  disableSubmit,
}) => {
  const [visible, setVisible] = useState(false)
  const [visibleRemoveEssay, setVisibleRemoveEssay] = useState(false)

  const handleShowModal = () => {
    setVisible(true)
  }

  const handleShowModalRemoveEssay = () => {
    setVisibleRemoveEssay(true)
  }

  const classes = cx("pt24 ButtonsForm", {
    "bg-white": !withoutBackground,
    ButtonsFixed: fixed,
    "ph16 ph16-ns ph32-m ph32-l": fixed,
    ph0: !fixed,
  })

  return (
    <Row className={classes} style={{ width: contentWidth || "100%" }}>
      <Col span={24}>
        <Form.Item>
          <div className="flex flex-column-reverse flex-row-ns flex-row-m flex-row-l justify-between-ns justify-between-m justify-between-l">
            <div>
              {showRemoveButton && (
                <Button
                  type="outline"
                  onClick={handleShowModalRemoveEssay}
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    borderRadius: "4px",
                    height: "42px",
                  }}
                  className="w-100 w-auto-ns w-auto-m w-auto-l mt16 mt0-ns mt0-m mt0-l"
                >
                  {removeButtonText}
                </Button>
              )}
            </div>

            <div className="flex flex-column-reverse items-center flex-row-ns flex-row-m flex-row-l justify-between-ns justify-between-m justify-between-l buttonsForm relative">
              <Button
                type="link"
                onClick={showConfirmModal ? handleShowModal : onCancel}
                className="mr0 mr40-ns mr40-m mr40-l ButtonCancel"
                style={{ fontSize: "16px", fontWeight: "bold" }}
              >
                {cancelText}
              </Button>

              {showSaveButton && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={buttonLoading}
                  className="w-100 w-auto-ns w-auto-m w-auto-l mb16 mb0-ns mb0-m mb0-l"
                  style={{
                    height: "42px",
                    borderRadius: "4px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                  onClick={onClickSave}
                  disabled={disableSubmit}
                >
                  {saveText}
                </Button>
              )}
            </div>
          </div>
        </Form.Item>

        <DialogModal
          dialog
          dialogType={DialogTypes.CONFIRM}
          visible={visible}
          title="Deseja sair dessa página?"
          dialogContent="As alterações realizadas não serão salvas."
          onCancel={onCancel}
          onOk={() => setVisible(false)}
          okText="Continuar na página"
          cancelText="Sair sem salvar"
        />

        <DialogModal
          dialog
          dialogType={DialogTypes.CONFIRM}
          visible={visibleRemoveEssay}
          title={removeDialogText}
          onCancel={() => setVisibleRemoveEssay(false)}
          onOk={() => {
            setVisibleRemoveEssay(false)
            handleRemoveEssay()
          }}
          okText="Remover"
          cancelText="Cancelar"
        />
      </Col>
    </Row>
  )
}
