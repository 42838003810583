import _get from "lodash/get"
import { useCallback, useLayoutEffect, useState } from "react"

export const useDimensions = (options) => {
  const [dimensions, setDimensions] = useState({})
  const [node, setNode] = useState(null)
  const [update, setUpdate] = useState(false)

  const ref = useCallback((node) => {
    setNode(node)
  }, [])

  const getDimensionObject = (node) => {
    const rect = node.getBoundingClientRect()

    return {
      width: rect.width,
      height: rect.height,
      top: "x" in rect ? rect.x : rect.top,
      left: "y" in rect ? rect.y : rect.left,
      x: "x" in rect ? rect.x : rect.left,
      y: "y" in rect ? rect.y : rect.top,
      right: rect.right,
      bottom: rect.bottom,
    }
  }

  useLayoutEffect(() => {
    const timeout = _get(options, "timeout", 0)
    const canScroll = _get(options, "canScroll", false)

    if (node) {
      const measure = () =>
        setTimeout(() => {
          setDimensions(getDimensionObject(node))
        }, timeout)

      window.addEventListener("resize", measure)

      if (canScroll) {
        window.addEventListener("scroll", measure)
      }

      measure()

      return () => {
        window.removeEventListener("resize", measure)
        window.removeEventListener("scroll", measure)
      }
    }
  }, [node, update])

  return [ref, dimensions, update, setUpdate]
}
